import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})

export class ChartRedirectionService {
  filterPayload: {};

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) { }

  /**
   * This function generates a payload based on the filters applied on the chart 
   * and navigates to the new chart based on the axes selected.
   * @param filter Filter data
   * @param graphAxes Graph axes data
   * @param val Value of the selected axis
   * @param primary_axis_ids Primary axis IDs
   * @returns void
   */
  generateFilterBasedPayload(filter, graphAxes, val = null, primary_axis_ids = null) {
    this.filterPayload = {}
    const appliedFilters = filter.filters.filter(item => item.filter_value);
    if (appliedFilters && appliedFilters.length > 0) {
      appliedFilters.forEach(item => {
        switch (item.filter_id) {
          case 4: // Done
            const fromToDate = item.filter_value.split(':');
            this.filterPayload['from_date'] = fromToDate[0];
            this.filterPayload['to_date'] = fromToDate[1];
            break;
          case 19:
            this.filterPayload['top_n'] = item.filter_value;
            break;
          case 21: // Done
            this.filterPayload['sub_admin'] = item.filter_value.split(',').map(item => parseInt(item));;
            break;
          case 20: // Done
            this.filterPayload['country_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 22: // Done
            this.filterPayload['created_by'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 18: // Done
            const deliveryFromToDate = item.filter_value.split(':');
            this.filterPayload['delivery_from_date'] = deliveryFromToDate[0];
            this.filterPayload['delivery_to_date'] = deliveryFromToDate[1];
            break;
          case 23: // Done
            this.filterPayload['product_code'] = item.filter_value;
            break;
          case 12: // Done
            this.filterPayload['category_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 6: // Done
            this.filterPayload['ship_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 5: // Done
            this.filterPayload['client_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 8: // Done
            this.filterPayload['vendor_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 7: // Done
            this.filterPayload['port_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 24: // Done
            this.filterPayload['purchaser_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
        }
      })
    }
    this.navigateViaAxes(graphAxes, val, primary_axis_ids);
  }

  /**
   * Navigate to different pages based on graph axes.
   * @param {number} graphAxes The axes of the graph.
   * @param {string} val The value of the graph.
   * @param {number} primary_axis_ids The primary axis id.
   */
  navigateViaAxes(graphAxes, val = null, primary_axis_ids = null) {
    let axes = graphAxes;
    if (axes) {
      if (axes == 71 || axes == 9 || axes == 10 || axes == 47) {
        this.filterPayload['order_status'] = [1, 2, 3, 4, 5, 6];
        this.checkForPrimaryAxis(primary_axis_ids);
        // this.router.navigateByUrl('/orders').then((result) => { window.open('/orders', '_blank') });
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 51 || axes == 52 || axes == 69 || axes == 22 || axes == 11 || axes == 43 || axes == 15 || axes == 72 || axes == 74 || axes == 50 || axes == 14 || axes == 13 || axes == 12 || axes == 13 || axes == 33) {
        this.filterPayload['order_status'] = [4];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 21) {
        this.filterPayload['order_status'] = [3];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 62 || axes == 24) {
        this.filterPayload['order_status'] = [5];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 20) {
        this.filterPayload['order_status'] = [1];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 32) {
        this.filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/port')
      }
      else if (axes == 23) {
        this.filterPayload['order_status'] = [6];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 73 || axes == 17) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?req_status=2&page=1');
      }
      else if (axes == 27) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/RFQs');
      }
      else if (axes == 48) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?req_status=1&page=1')
      }
      else if (axes == 19) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?priority_type=Urgent&page=1')
      }
      else if (axes == 54 || axes == 55 || axes == 8) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?req_status=-1&page=1');
      }
      else if (axes == 16) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?req_status=0&page=1');
      }
      else if (axes == 18) {
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/requisitions?req_status=3&page=1');
      }
      else if (axes == 7) {
        this.filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/company?type=2&page=1');
      }
      else if (axes == 5) {
        this.filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/company?type=1&page=1');
      }
      else if (axes == 65 || axes == 42) {
        if (val)
          this.filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/dashboard/catalogue')
      }
      else if (axes == 67 || axes == 6) {
        this.filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/user')
      }
      else if (axes == 64) {
        this.filterPayload['order_number'] = val.split('/ ')[1];
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 58 || axes == 76 || axes == 31 || axes == 60 || axes == 59 || axes == 61 || axes == 75 || axes == 78 || axes == 76) {
        if (val) {
          this.filterPayload['name'] = val;
        }
        this.checkForPrimaryAxis(primary_axis_ids);
        this.router.navigateByUrl('/ship');
      }
      else if (axes == 26) {
        this.router.navigateByUrl('/RFQs/products-expired');
      }
      else {
        this.toastr.error('', 'No Route Found')
      }
    }
    localStorage.setItem('filterPayload', JSON.stringify(this.filterPayload));
  }

  /**
   * checkForPrimaryAxis(): This method is used for setting filterPayload based on selected primary axis.
   * axes: this variable storing the object of selected primary axis.
   */
  checkForPrimaryAxis(axes) {
    if (axes && Object.keys(axes).length) {
      switch (axes.axis_name) {
        case 'Time (Month)':
          let month = new Date(axes.id).getMonth();
          let year = new Date(axes.id).getFullYear();
          this.filterPayload['from_date'] = new Date(year, month, 1);
          this.filterPayload['delivery_from_date'] = new Date(year, month, 1);
          if (month == 12) {
            this.filterPayload['to_date'] = new Date(year + 1, 1, 1);
            this.filterPayload['delivery_to_date'] = new Date(year + 1, 1, 1);
          } else {
            this.filterPayload['to_date'] = new Date(year, month + 1, 1);
            this.filterPayload['delivery_to_date'] = new Date(year, month + 1, 1);
          }
          break;
        case 'Ship(s)':
          this.filterPayload['ship_ids'] = [axes.id];
          break;
        case 'Purchaser(s)':
          this.filterPayload['purchaser_ids'] = [axes.id];
          break;
        case 'Port(s)':
          this.filterPayload['port_ids'] = [axes.id];
          break;
        case 'Vendor(s)':
          this.filterPayload['vendor_ids'] = [axes.id];
          break;
        case 'Category(s)':
          this.filterPayload['category_ids'] = [axes.id];
          break;
        case 'Shipskart Executives':
          this.filterPayload['sub_admin'] = [axes.id];
          break;
        case 'Country(s)':
          this.filterPayload['country_ids'] = [axes.id];
          break;
      }
    }
  }
}