import { Company } from '@app/modules/company/models/company.modal';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { SharedService } from '@app/shared/service/shared.service';
import { UserRoles } from '@app/core/enums/user-roles';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';

@Injectable({ providedIn: 'root' })
export class ProductService {
  userRole = UserRoles;
  company: Company;
  constructor(private http: HttpClient,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private formDataToJson: FormDataToJsonService) { }

  getAll(params, headers = null, productPrices = false) {
    const role = this.sharedService.getUserRole();
    this.company = this.sharedService.getCompany();
    if ((role === this.userRole.ROLE_ADMIN && productPrices) || (role === this.userRole.ROLE_SUBADMIN && productPrices) || (role === this.userRole.ROLE_COMPANY_ADMIN && this.company.type === 2 && productPrices)) {
      return this.http
        .get<{ data }>(`${environment.apiUrl}/v1/product/list`, {
          params, headers
        })
        .pipe(
          map(dataVal => {
            return dataVal.data;
          })
        );
    } else {
      return this.http
        .get<{ data }>(`${environment.apiUrl}/v1/product/`, { params, headers })
        .pipe(
          map(dataVal => {
            return dataVal.data;
          })
        );
    }
  }

  getFilter(params) {
    const role = this.sharedService.getUserRole();
    if (role === this.userRole.ROLE_ADMIN || role === this.userRole.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      return this.http
        .get<{ data }>(`${environment.apiUrl}/v1/product/admin-search`, { params })
        .pipe(
          map(dataVal => {
            return dataVal.data;
          })
        );
    }
  }

  addProduct(product) {
    return this.http.post<any>(`${environment.apiUrl}/v1/product/`, product);
  }

  editProduct(product, id: number) {
    return this.http.put(`${environment.apiUrl}/v1/product/${id}`, product);
  }

  getByid(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/product/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getByCategoryId(id: number, catId = 0, page, limit, search?, shipId?, portId = 0, portIdCatalog = 0, requirementId?, countryId = 0) {
    let url = '';
    if (id) {
      url = `${environment.apiUrl}/v1/product/category/${id}/${catId}?page=${page}&limit=${limit}`;
    } else {
      url = `${environment.apiUrl}/v1/product/category?page=${page}&limit=${limit}`;
    }

    if (search) {
      url = url + `&search=${search}`;
    }

    if (shipId) {
      url = url + `&ship_id=${shipId}`;
    }

    if (portId) {
      url = url + `&port_id=${portId}`;
    }
    if (portIdCatalog) {
      url = url + `&catalogue_port_id=${portIdCatalog}`;
    }

    if (countryId) {
      url = url + `&country_id=${countryId}`;
    }

    if (requirementId) {
      url = url + `&requirement_id=${requirementId}`;
    }

    return this.http.get<string>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  updateExpiryDate(data) {
    return this.http.put(`${environment.apiUrl}/v1/product/price`, data);
  }

  getUnits(limit) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/unit/?limit=${limit}`)
      .pipe(
        map(data => {
          return data.data.results;
        })
      );
  }
  async createUnits(unit): Promise<JSON> {
    const dataToJson = this.formDataToJson.convertFormDataToJson(unit);
    return await this.http.post<JSON>(`${environment.apiUrl}/v1/unit/`, dataToJson).toPromise();
  }
  uploadProduct(product) {
    return this.http.post(`${environment.apiUrl}/v1/product/import`, product);
  }
  uploadImg(img) {
    return this.http.post(`${environment.apiUrl}/v1/product/uploadImages`, img);
  }
  export(params, header) {
    return this.http
      .get(`${environment.apiUrl}/v1/product/export`, {
        params,
        headers: header,
        responseType: 'blob'
      })
      .pipe(
        map(dataVal => {
          return dataVal;
        })
      );
  }

  getProductSuggestion(id: number, search = null, selectedCategory = 0, portId = 0, countryId = 0) {
    if (search != null) {
      search = search.replace(/[/#%]/g, '');
    }
    if (selectedCategory) {
      const url = `${environment.apiUrl}/v1/product/search/${search}/${selectedCategory}/${id}/${portId}/${countryId}`;
      return this.http.get<string>(url).pipe(
        map(dataVal => {
          return dataVal;
        })
      );
    } else {
      const url = `${environment.apiUrl}/v1/product/search/${search}/${selectedCategory = 0}/${id = 0}/${portId ? portId : 0}/${countryId ? countryId : 0}`;
      return this.http.get<string>(url).pipe(
        map(dataVal => {
          return dataVal;
        }))
    }
  }

  addProductRequest(data) {
    return this.http.post<any>(`${environment.apiUrl}/v1/product/request/`, data);
  }

  async addProductSpecificRequest(data): Promise<any> {
    try {
      return await this.http.post<any>(`${environment.apiUrl}/v1/product/request/`, data).toPromise();
    } catch (err) {
      return this.toastr.error(`Product description can't be more than 300 character.`);
    }
  }

  getProductRequest(params) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/product/request/`, {
        params,
      })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  getProductRequestSuggestion(search = null) {
    const url = `${environment.apiUrl}/v1/product/request/search/${search}`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  deleteProduct(productId): Observable<any> {
    const url = `${environment.apiUrl}/v1/product/${productId}`;
    return this.http.delete(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  getProductVariants(productId = null) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/product/getVariants/${productId}`)
      .pipe(map(dataVal => {
        return dataVal;
      })
      );
  }

  getQuotedPrices(orderId, vendorId) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/product/get_quoted_product_prices/${orderId}/${vendorId}`)
      .pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  getProductDetails(prod_id, port_id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/product/price/${prod_id}/${port_id}`).pipe(map(dataVal => {
      return dataVal.data;
    }));
  }

  getQuotedVendorPrices(orderId, vendorIds) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/product/get_quoted_vendor_product_prices/${orderId}/${vendorIds}`)
      .pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  updateQuotePrice(payload, requisitionId, vendorId) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/product/update_quotation_prices/${requisitionId}/${vendorId}`,
      payload
    );
  }

  getPriceHistory(formData) {
    const formToJson = this.formDataToJson.convertFormDataToJson(formData);
    return this.http.post<{ data }>(`${environment.apiUrl}/v1/product/get_product_price_history`, formToJson)
      .pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  addDiscountOnQuotePrice(payload, requisitionId, vendorId) {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/product/apply_discount_from_vendor/${requisitionId}/${vendorId}`,
      payload
    );
  }
  getSyncWithzoho() {
    const url = `${environment.apiUrl}/inventory_api/inventory/add_edit_page_data_sources_from_zoho`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }
}
